<script setup>
  import { getCurrStep } from '@/api/dashboard.js'
  import { computed, reactive } from 'vue'
  import { useRouter } from 'vue-router'
  import { CircleCheck, CircleCheckFilled } from '@element-plus/icons'
  import Store from "store"

  // 获取 token
  let token
  if (process.env.VUE_APP_CUSTOM_ENV === 'development') {
      token = process.env.VUE_APP_TOKEN
  } else {
      token = Store.get('diyToken')
  }

  // 步骤内容
  const stepData = reactive([
    {
      title: 'Sign up for 99diy',
      desc: [],
      complete: false
    },
    {
      title: 'Create a product template',
      desc: [
        {
          text: 'Pick a product,',
          type: 'keypoint',
          url: process.env.VUE_APP_URL_PRODUCTS + `?diyToken=${token}`
        },
        {
          text: ' add a design, save it, and use it as you like.',
          type: 'common'
        }
      ],
      complete: false
    },
    {
      title: 'Connect your store',
      desc: [
        {
          text: 'Select your ecommerce platform or marketplace',
          type: 'keypoint',
          route: '/store/addNewStore'
        },
        {
          text: ' and start selling.',
          type: 'common'
        }
      ],
      complete: false
    },
    {
      title: 'Add products to your store',
      desc: [
        {
          text: 'Create new product',
          type: 'keypoint',
          route: '/store'
        },
        {
          text: ' and',
          type: 'common'
        },
        {
          text: ' publish your product template',
          type: 'keypoint',
          route: '/store'
        },
        {
          text: ' to your store.',
          type: 'common'
        }
      ],
      complete: false
    },
    {
      title: 'Set up your billing info',
      desc: [
        {
          text: 'Add a billing method',
          type: 'keypoint',
          route: '/myAccount'
        },
        {
          text: ' so we can fulfill your orders.',
          type: 'common'
        }
      ],
      complete: false
    },
    {
      title: 'Make an order',
      desc: [
        {
          text: 'Make an order',
          type: 'keypoint',
          route: '/store'
        },
        {
          text: ' and see what your product looks like in real life.',
          type: 'common'
        }
      ],
      complete: false
    }
  ])

  // 计算已完成任务的个数
  const completeNumber = computed(() => {
    return stepData.filter(item => item.complete).length
  })

  // 获取已完成的步骤
  const {data} = await getCurrStep()
  data.forEach((item) => {
    stepData[item - 1].complete = true
  })
  
  // 获取路由对象
  const router = useRouter()
  
  // 页面跳转
  const goPage = (route, url) => {
    route && router.push(route)
    if (url) {
      window.location.href = url
    }
  }
</script>

<template>
  <div class="step_wrap">
    <div class="step_item" v-for="(step, stepIndex) in stepData" :key="stepIndex">
      <el-icon class="step_check" color="#1EAB7F" v-if="step.complete">
        <circle-check-filled />
      </el-icon>
      <el-icon class="step_check" color="#CCCCCC" v-else>
        <!-- <circle-check /> -->
        <div class="circle"></div>
      </el-icon>
      <div>
        <div class="step_title">{{step.title}}</div>
        <div class="step_desc">
          <span v-for="(desc, descIndex) in step.desc" :key="descIndex" :class="desc.type==='keypoint' ? 'key_text' : 'sub_text'" @click="goPage(desc.route, desc.url)">
            {{desc.text}}
          </span>
        </div>
      </div>
    </div>
  </div>
  <div class="progress_bar">
    <div :class="['bar', step.complete ? 'highlight' : '']" v-for="(step, stepIndex) in stepData" :key="stepIndex"></div>
  </div>
  <div class="progress_text">
    {{completeNumber}} out of 6 steps completed
  </div>
</template>

<style scoped lang="scss">
  .sub_text {
    color: rgba(0, 0, 0, 0.5);
    font-size: 14px;
    line-height: 1.7;
  }
  .key_text {
    color: #000;
    text-decoration: underline;
    cursor: pointer;
  }
  .step_wrap {
    .step_item {
      display: flex;
      align-items: flex-start;
      .step_check {
        flex: 0;
        font-size: 23px;
        margin-right: 10px;
        margin-top: -2px;
        .circle {
          width: 20px;
          height: 20px;
          border-radius: 50%;
          border: 2px solid rgba(0, 0, 0, 0.2);
        }
      }
      .step_title {
        font-size: 16px;
        font-weight: bold;
      }
      .step_desc {
        margin-top: 5px;
        line-height: 1.7;
      }
      & + .step_item {
        margin-top: 24px;
      }
    }
  }
  .progress_bar {
    display: flex;
    margin-top: 40px;
    .bar {
      flex: 1;
      background-color: #ccc;
      height: 4px;
      border-radius: 2px;
      &.highlight {
        background-color: #FF2379;
      }
      & + .bar {
        margin-left: 10px;
      }
    }
  }
  .progress_text {
    margin-top: 10px;
    text-align: right;
    font-size: 14px;
    color: rgba(0, 0, 0, 0.5);
  }
</style>
