<script setup>
import { ref, computed, onMounted, watchPostEffect } from 'vue'
import copyrightInfo from '@/components/copyrightInfo'
import storeList from './components/storeList'
import designedList from './components/designedList'
import orderList from './components/orderList'
import stepList from './components/stepList'
import yourCoins from './components/yourCoins'
import { useStore } from 'vuex'

const store = useStore()
const name = computed(() => store.state.user.userInfo.fullName)
const email = computed(() => store.state.user.userInfo.email)

const storeRef = ref()
// watchPostEffect(() => {
//   storeRef.value?.storeList
// })
const active = sessionStorage.getItem('operate')
if (active === 'active') {
  gtag('event', 'conversion', {
    send_to: 'AW-10926694714/5J6iCP3KxcUDELrCoNoo',
  })

  //注册成功 广告埋点
  rdt('track', 'SignUp')

  //99Diy GA注册事件绑定
  gtag('event', 'click', {
    send_to: '213970580',
  })

  sessionStorage.removeItem('operate')
}
</script>

<template>
  <div class="welcome_words">
    <div class="text_box media_width">
      <div class="name">Welcome, {{ name || email }}!</div>
      <div class="describe">We hope that you will enjoy your stay with us.</div>
    </div>
  </div>
  <div class="content media_width">
    <div class="c_left">
      <div class="section">
        <div class="section_flex">
          <div class="title">My stores</div>
          <div
            class="more"
            @click="$router.push('/store')"
            v-if="
              storeRef?.storeList?.value.length > 1 && !storeRef?.showConnect
            "
          >
            View more
          </div>
        </div>
        <suspense>
          <store-list ref="storeRef" />
        </suspense>
      </div>

      <div class="section">
        <div class="title">Recently designed</div>
        <suspense>
          <designed-list />
        </suspense>
      </div>

      <div class="section">
        <div class="title">Recently orders</div>
        <suspense>
          <template #default>
            <order-list />
          </template>
          <template #fallback>
            <div v-loading="true" style="height: 288px"></div>
          </template>
        </suspense>
      </div>
    </div>
    <div class="c_right">
      <div class="section">
        <div class="section_flex">
          <div class="title">Your Coins</div>
          <div class="more" @click="$router.push('/Coins')">View more</div>
        </div>
        <suspense>
          <your-coins />
        </suspense>
      </div>
      <div class="section">
        <div class="title">Your next steps</div>
        <div class="sub_title_text">
          Set up your business, and start making money with 99diy!
        </div>
        <suspense>
          <step-list />
        </suspense>
      </div>
    </div>
  </div>

  <copyright-info />
</template>

<style scoped lang="scss">
.welcome_words {
  height: 160px;
  background: #fff;
  overflow: hidden;
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
  .text_box {
    margin: 50px auto 0;
  }
  .name {
    font-size: 36px;
    font-family: PingFangSC-Semibold, PingFang SC;
    font-weight: 600;
    color: rgba(0, 0, 0, 0.85);
    line-height: 50px;
  }
  .describe {
    font-size: 16px;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: rgba(0, 0, 0, 0.5);
  }
}
.section {
  background-color: #fff;
  padding: 24px;
  margin-bottom: 20px;
  border: 1px solid rgba(0, 0, 0, 0.1);
  .title {
    font-size: 24px;
    font-weight: bold;
  }
  &_flex {
    display: flex;
    justify-content: space-between;
    .more {
      line-height: 28px;
      font-size: 14px;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 600;
      color: #ff2379;
      cursor: pointer;
    }
  }
}
.content {
  margin: 30px auto 0;
  overflow: hidden;
  .c_left {
    width: 670px;
    float: left;
  }
  .c_right {
    padding-left: 700px;
    .sub_title_text {
      margin-top: 10px;
      margin-bottom: 30px;
      color: rgba(0, 0, 0, 0.5);
      font-size: 14px;
      line-height: 1.7;
    }
  }
}

//1432 开始 - 1px
//1200  887px
@media screen and (min-width: 1450px) {
  .media_width {
    width: 1100px !important;
  }
}
@media screen and (min-width: 1400px) and (max-width: 1449px) {
  .media_width {
    width: 1050px !important;
  }
}
@media screen and (min-width: 1350px) and (max-width: 1399px) {
  .media_width {
    width: 1000px !important;
  }
}
@media screen and (min-width: 1300px) and (max-width: 1349px) {
  .media_width {
    width: 950px !important;
  }
}
@media screen and (min-width: 1250px) and (max-width: 1299px) {
  .media_width {
    width: 900px !important;
  }
}
@media screen and (min-width: 1100px) and (max-width: 1249px) {
  .media_width {
    width: 800px !important;
  }
}
@media screen and (max-width: 1099px) {
  .media_width {
    width: 670px !important;
  }
}
@media screen and (max-width: 1299px) {
  .c_left {
    float: none !important;
  }
  .c_right {
    padding-left: 0 !important;
    width: 670px !important;
  }
}
</style>
