<script setup>
import { reactive } from "vue";
import { getDesignedList } from "@/api/dashboard";
import { CirclePlusFilled } from "@element-plus/icons";
import Creating from "@/assets/product/Creating.gif";
import Store from "store";

// 获取列表数据
const designedList = reactive({
  value: [],
});
const { data } = await getDesignedList();
designedList.value = data;

// 获取 token
let token;
if (process.env.VUE_APP_CUSTOM_ENV === "development") {
  token = process.env.VUE_APP_TOKEN;
} else {
  token = Store.get("diyToken");
}
// 跳转页面
const goPage = () => {
  window.location.href =
    process.env.VUE_APP_URL_PRODUCTS + `?diyToken=${token}`;
};
</script>

<template>
  <div class="list_wrap">
    <div class="design_item design_btn" @click="goPage()">
      <el-icon class="plus_icon">
        <circle-plus-filled />
      </el-icon>
      <div class="btn_text">
        <div>Create new</div>
        <div>design</div>
      </div>
    </div>
    <div
      class="design_item design_image"
      v-for="(item, index) in designedList.value"
      :key="index"
    >
      <div v-if="!!item.img.length" class="image_box">
        <el-image :src="item?.img[0]?.img"></el-image>
      </div>
      <div class="creating_box" v-else>
        <el-image class="creating_box_img" :src="Creating"></el-image>
        <div class="creating_box_text">Creating...</div>
      </div>
      <div class="design_name" :title="item.name">{{ item.name }}</div>
      <div class="design_num">
        <span style="margin-right:3px">{{ item.design_colors.length }}</span>
        <span v-for="val in item.product.specification" :key="val.level">
          <template v-if="Number(val.level) === 1">
            {{ val.name }}
          </template>
        </span>
        <span> · </span>
        <span style="margin-right:3px">{{ item.item.length }}</span>
        <span>
          <span v-for="val in item.product.specification" :key="val.level">
            <template v-if="Number(val.level) === 2">
              {{ val.name }}
            </template>
          </span>
        </span>
      </div>
    </div>
  </div>
  <div class="btn_wrap" v-if="designedList.value.length === 3">
    <el-button type="primary" @click="$router.push('/store')"
      >View more</el-button
    >
  </div>
</template>

<style scoped lang="scss">
.list_wrap {
  width: 620px;
  margin-top: 20px;
  overflow: hidden;
  .design_item {
    height: 230px;
    width: 140px;
    float: left;
    & + .design_item {
      margin-left: 20px;
    }
  }
  .design_btn {
    cursor: pointer;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
    border: 1px dashed rgba(0, 0, 0, 0.2);
    .plus_icon {
      color: #1eab7f;
      font-size: 35px;
    }
    .btn_text {
      font-size: 14px;
      font-weight: bold;
      line-height: 1.7;
      margin-top: 20px;
    }
  }
  .design_image {
    border: 1px solid rgba(0, 0, 0, 0.03);
    .image_box {
      width: 100%;
      height: 140px;
      border-bottom: 1px solid rgba(0, 0, 0, 0.03);
    }
    .creating_box {
      width: 100%;
      height: 140px;
      border-bottom: 1px solid rgba(0, 0, 0, 0.03);
      &_img {
        padding: 30px 40px;
      }
      &_text {
        text-align: center;
        margin-top: -20px;
        color: rgba(0, 0, 0, 0.5);
      }
    }
    .design_name {
      height: 45px;
      padding: 10px 10px 0;
      font-weight: bold;
      text-overflow: -o-ellipsis-lastline;
      word-break: break-all;
      overflow: hidden;
      text-overflow: ellipsis;
      display: -webkit-box;
      -webkit-line-clamp: 2;
      line-clamp: 2;
      -webkit-box-orient: vertical;
    }
    .design_num {
      margin-top: 12px;
      color: rgba(0, 0, 0, 0.5);
      padding: 0 10px;
    }
  }
}
.btn_wrap {
  margin-top: 20px;
  text-align: right;
}
</style>
