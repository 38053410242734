<script setup>
import { defineExpose, ref } from 'vue'
import '@/assets/font/iconfont.js'
import { getStoreList } from '@/api/dashboard'
import { getPlatformList } from '@/api/myStore'
import { reactive } from 'vue'
import { useRouter } from 'vue-router'
import defaultStore from '@/assets/storeImg/defaultStore.png'
import shopline from '@/assets/connectStore/shopline.png'
// 店铺列表数据
const storeList = reactive({
  value: [],
})
const { data } = await getStoreList()
// 支持的第三方平台 logo
// const res = await getPlatformList();
// res.data.forEach((item) => {
//   data.forEach((sub) => {
//     if (item.code === sub.platform_code) {
//       sub.logo = item.logo;
//     }
//   });
// });
storeList.value = data.map((e) => {
  e.logo = e?.platform?.logo || ''
  return e
})

const showConnect = ref(true)

storeList.value.forEach((item) => {
  if (item.is_authorize === 1) {
    showConnect.value = false
  }
})

let router = useRouter()
let viewStore = (code) => {
  sessionStorage.setItem('setStoreCode', code)
  router.push('/store')
}

defineExpose({
  storeList,
  showConnect,
})
</script>

<template>
  <div class="platform_wrap" v-if="showConnect">
    <div class="platform_item">
      <div class="platform_logo">
        <svg class="icon svg-icon" aria-hidden="true">
          <use xlink:href="#icon-social-shopify"></use>
        </svg>
      </div>
      <div class="platform_desc">
        Ecommerce platform with everything you need to sell online.
      </div>
      <el-button
        type="primary"
        style="width: 100%"
        @click="router.push('/store/addNewStore?platform=Shopify')"
        >Connect</el-button
      >
    </div>
    <div class="platform_item">
      <div class="platform_logo">
        <!-- <svg class="icon svg-icon" aria-hidden="true">
          <use xlink:href="#icon-woocommerce"></use>
        </svg> -->
        <el-image
          :src="shopline"
          style="width: 50px; height: 50px; margin-top: 10px"
        ></el-image>
      </div>
      <div class="platform_desc" style="padding-left: 20px">
        No design experience or coding required. Build an online store with
        ease.
      </div>
      <el-button
        type="primary"
        style="width: 100%"
        @click="router.push('/store/addNewStore?platform=Shopline')"
        >Connect</el-button
      >
    </div>
    <div class="platform_item">
      <div class="platform_logo">
        <svg class="icon svg-icon" aria-hidden="true">
          <use xlink:href="#icon-wix"></use>
        </svg>
      </div>
      <div class="platform_desc">
        A platform with an intultive drag and drop builder and 500+ customizable
        designer-made templates.
      </div>
      <el-button type="info" style="width: 100%" plain disabled
        >Not yet open</el-button
      >
    </div>
  </div>
  <div class="list_wrap" v-else>
    <div
      class="store_item"
      v-for="(item, index) in storeList.value"
      :key="index"
    >
      <div class="platform_logo">
        <el-image
          style="width: 100%; height: 100%"
          :src="item.logo || defaultStore"
          alt=""
          fit="cover"
        />
      </div>
      <div class="store_name">
        {{ item.name }} {{ item.is_default ? '( default )' : '' }}
      </div>
      <div class="store_status">
        <el-tag
          v-if="item.is_authorize_word === 'Active'"
          size="small"
          class="active"
          >{{ item.is_authorize_word }}</el-tag
        >
        <el-tag
          v-if="item.is_authorize_word === 'Unauthorized'"
          size="small"
          class="unauthorized"
          >{{ item.is_authorize_word }}</el-tag
        >
      </div>
      <div class="view_btn">
        <el-button type="info" style="width: 100%" @click="viewStore(item.code)"
          >View Store</el-button
        >
      </div>
    </div>
  </div>
</template>

<style scoped lang="scss">
.list_wrap {
  .store_item {
    display: flex;
    align-items: center;
    padding: 20px;
    border: 1px solid rgba(0, 0, 0, 0.1);
    margin-top: 20px;
    .platform_logo {
      width: 40px;
      height: 40px;
    }
    .store_name {
      margin: 0 20px;
      font-weight: bold;
    }
    .store_status {
      flex: 1;
      padding-right: 20px;
      .active {
        line-height: 24px;
        border-radius: 12px;
        background: #1eab7f;
        border: none;
        color: #fff;
      }
      .unauthorized {
        line-height: 24px;
        border-radius: 12px;
        background-color: #eaeaea;
        border: none;
        color: rgba(0, 0, 0, 0.85);
      }
    }
  }
}
.platform_wrap {
  display: flex;
  margin-top: 20px;
  .platform_item {
    flex: 1;
    border: 1px solid rgba(0, 0, 0, 0.1);
    padding: 0 15px 15px;
    color: rgba(0, 0, 0, 0.85);
    line-height: 1.6;
    height: 250px;
    display: flex;
    flex-direction: column;
    .platform_logo {
      font-size: 50px;
      height: 80px;
      text-align: center;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
    }
    & + .platform_item {
      margin-left: 15px;
    }
    .platform_desc {
      flex: 1;
    }
  }
}
.icon {
  width: 1em;
  height: 1em;
  vertical-align: -0.15em;
  fill: currentColor;
  overflow: hidden;
}
</style>
