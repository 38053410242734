<template>
  <div>
    <div class="count">
      <i class="iconfont icon-meiyuan"></i><span>{{ integrate }}</span>
    </div>
    <div class="des">
      The coins can be used to deduct a portion of the order amount or redeem coupons ({{ coin }} Coins = USD {{ usd }})
    </div>
  </div>
</template>

<script setup>
import { computed } from 'vue'
import { useStore } from 'vuex'

const $store = useStore()
// $store.dispatch('coins/coinsAction')
const integrate = computed(() => $store.state.user.userInfo.integrate)
const coin = computed(() => $store.state.user.coins)
const usd = computed(() => $store.state.user.usd)
</script>

<style lang="scss" scoped>
.count {
  height: 100px;
  line-height: 100px;
  font-size: 36px;
  font-family: PingFangSC-Semibold, PingFang SC;
  font-weight: 600;
  color: rgba(0, 0, 0, 0.85);
  i {
    font-size: 36px;
    color: #FFC400;
    margin-right: 12px;
  }
}
.des {
  margin-top: 10px;
  color: rgba(0, 0, 0, 0.5);
  font-size: 14px;
  line-height: 1.7;
}
</style>