<script setup>
  import { getOrderList, getStoreList } from '@/api/dashboard.js'
  import { reactive, ref } from 'vue'
  import { useRouter } from 'vue-router';
  import empty from '@/components/empty'

  // 获取订单数据
  const orderList = reactive({
    value: []
  })
  const {data} = await getOrderList()
  // 获取店铺列表
  // const { data: res } = await getStoreList()
  // // 给订单数据添加店铺名称
  // data.data.forEach(item => {
  //   res.forEach(sub => {
  //     if (item.platform_store_code === sub.code) {
  //       item.name = sub.name
  //     }
  //   })
  // })
  // 获取最近的五条数据
  orderList.value = data.data.filter((e,i)=>{
    e.name = e.store.name
    return i < 5
  })

  // 跳转到店铺
  const router = useRouter()
  let viewStore = (code) => {
    sessionStorage.setItem('setStoreCode',code)
    router.push('/store')
  }
</script>

<template>
  <div class="list_wrap" v-if="orderList.value.length">
    <el-table :data="orderList.value">
      <el-table-column prop="number" label="Order ID" align="center" width="160">
        <template #default="{ row }">
          <span class="number" :title="row.number">{{ row.number }}</span>
        </template>
      </el-table-column>
      <el-table-column prop="created_at" label="Order time" align="center" width="160" />
      <el-table-column prop="total_price" label="Order cost" align="center" width="160">
        <template #default="{ row }">
          <span>$ {{ row.total_price }}</span>
        </template>
      </el-table-column>
      <el-table-column prop="name" label="Store" align="center">
        <template #default="{ row }">
          <span class="name" @click="viewStore(row.platform_store_code)" :title="row.name">{{ row.name }}</span>
        </template>
      </el-table-column>
    </el-table>
  </div>
  <empty v-else :text="'No order yet'" :height="'288px'" />
</template>

<style scoped lang="scss">
  .list_wrap {
    margin-top: 20px;
    .name {  
      color: #1EAB7F;
      cursor: pointer;
      white-space: nowrap;
      text-overflow: ellipsis;
      overflow: hidden;
    }
  }
  .number {
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
  }
</style>
